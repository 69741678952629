<template>
  <v-app-bar class="mt-1" flat dense>
    <v-container class="mx-auto py-0">
      <v-row align="center">
        <v-img
          :src="require('@/assets/logoMini.png')"
          class="mr-5"
          contain
          height="48"
          width="48"
          max-width="48"
          @click="$vuetify.goTo(0)"
        />

        <v-btn
          v-for="(link, i) in links"
          :key="i"
          v-bind="link"
          class="hidden-sm-and-down"
          text
          @click="onClick($event, link)"
        >
          {{ link.text }}
        </v-btn>

        <v-spacer />

        <v-text-field
          append-icon="mdi-magnify"
          flat
          hide-details
          solo-inverted
          style="max-width: 300px;"
        />
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
// Utilities
import { mapState } from "vuex";

export default {
  name: "CoreAppBar",

  computed: {
    ...mapState([
      "metadados",
      "user",
      "dialogFollowUp",
      "isReload",
      "tabs",
      "menuCtCrm",
    ]),
  },

  methods: {},
};
</script>
