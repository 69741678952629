import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAppBar,{staticClass:"mt-1",attrs:{"flat":"","dense":""}},[_c(VContainer,{staticClass:"mx-auto py-0"},[_c(VRow,{attrs:{"align":"center"}},[_c(VImg,{staticClass:"mr-5",attrs:{"src":require('@/assets/logoMini.png'),"contain":"","height":"48","width":"48","max-width":"48"},on:{"click":function($event){return _vm.$vuetify.goTo(0)}}}),_vm._l((_vm.links),function(link,i){return _c(VBtn,_vm._b({key:i,staticClass:"hidden-sm-and-down",attrs:{"text":""},on:{"click":function($event){return _vm.onClick($event, link)}}},'v-btn',link,false),[_vm._v(" "+_vm._s(link.text)+" ")])}),_c(VSpacer),_c(VTextField,{staticStyle:{"max-width":"300px"},attrs:{"append-icon":"mdi-magnify","flat":"","hide-details":"","solo-inverted":""}})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }